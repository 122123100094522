<template>
  <div :class="mode ? 'sal-jetty' : 'sal-jetty-light'">
    <!-- :style="{
      'background-image':
        'url(' + require('@/assets/images/ptsal/bg1compress.jpeg') + ')'
    }" -->
    <b-card class="sal-header sal-title">
      <div class="divider">
        <div class="divider-text">
          <h3>JETTY VIEW TODAY</h3>
          <!-- <small>Yang Sedang Sandar</small> -->
          <small>Monitoring kondisi Jetty saat ini.</small>
          <br />
          <b-button
            @click="changeMode()"
            variant="primary"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          >
            {{ mode ? '3D View' : '2D View' }}
          </b-button>
          <div v-if="yo_isLoading" class="progress-wrapper">
            <b-card-text class="mb-0">
              Memuat data… {{ yo_isLoadingValue + '%' }}
            </b-card-text>
            <b-progress
              :key="yo_isLoadingValue"
              animated
              :value="yo_isLoadingValue"
              variant="info"
              max="100"
              :class="'progress-bar-info'"
            />
          </div>
          <!-- {{ playLottie }} -->
        </div>
      </div>
    </b-card>

    <b-row class="sal-content row-content justify-content-center match-height">
      <b-col md="2" class="column" v-for="(jettyItem, jettyKey) in jetty" :key="jettyKey">
        <b-card class="card-count justify-content-center">
          <h5 class="match-height">{{jettyKey}}</h5>
          <hr>
          <small style="font-size: 14px" class="justify-content-center text-center">
            Rencana Sandar:
            <b-badge
              variant="primary"
              v-b-popover.hover.top.html="formatJettyChildren(jettyItem?.children_rencana)"
              :content="content"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              title="🔖 Kapal Rencana Sandar"
            >
              {{ jettyItem.rencana_sandar }}
            </b-badge>
            <hr>
            <strong>Sandar : </strong>
            <b-badge variant="warning">
              {{ jettyItem.sandar }}
            </b-badge>
          </small>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="sal-content-sandar row-content justify-content-center">
      <b-col md="2" class="column" v-for="(jettyItem, jettyKey) in jetty" :key="jettyKey">
        <b-card
          v-for="(item, index) in jettyItem.children"
          :key="index"
          class="card-list-item"
        >
          <div class="row-item">
            <div class="col-item">
              <div class="card-item mb-0">
                <b-row>
                  <b-col cols="12">
                    <b-img
                      lazy
                      src="boat2.png"
                      class="congratulation-medal image"
                      height="130"
                      style="opacity: 0.1"
                    />
                  </b-col>
                  <b-col>
                    <small>
                      <b-badge :variant="'info'">
                        <feather-icon icon="UsersIcon" class="" />
                        <span style="font-size: 9px">
                            {{ item.pemilik }}
                        </span>
                      </b-badge>
                      <!-- <b-badge :variant="'primary'" class="ml-1">
                        <feather-icon icon="HashIcon" class="" />
                        <span style="font-size: 9px">
                            {{ item.pemilik }}
                        </span>
                      </b-badge> -->
                      <hr>
                      <small
                        v-b-tooltip.hover
                        title="Nomor PKK"
                        class="cursor-pointer"
                      >
                        <span
                          v-if="!item.show_in"
                          style="font-size: 9px"
                          @click="copyText(item.no_pkk || item.no_pkk_tug_boat)"
                        >
                          <strong>{{ item.no_pkk || item.no_pkk_tug_boat }}</strong>
                        </span>
                        <span
                          v-else
                          @click="copyText(item.no_pkk_tug_boat)"
                          style="font-size: 9px"
                        >
                          <strong>{{ item.no_pkk_tug_boat }}</strong>
                        </span>
                      </small>
                      <hr />
                      <strong v-b-tooltip.hover title="Nama Kapal">
                        <span v-if="!item.show_in" style="font-size: 14px">
                          <strong>{{ item.boat_name || item.name_tug_boat }}</strong>
                        </span>
                        <span v-else style="font-size: 14px">
                          <strong>{{ item.name_tug_boat }}</strong>
                        </span>
                      </strong>
                      <hr />
                      <span v-b-tooltip.hover title="Kegiatan">
                        <strong style="font-size: 11px">
                          {{ item.kegiatan }}
                        </strong>
                        <!-- 25 December 2022 - 12:05 PM -->
                      </span>
                      <hr />
                      <span v-if="item.detail_type === 'form_chain_tbbm'">
                        <b-button
                          :variant="
                            summaryPermission(item, 'TBBM')
                              ? 'outline-danger'
                              : 'outline-primary'
                          "
                          :disabled="summaryPermission(item, 'TBBM')"
                          block
                          size="sm"
                          :to="{
                            name: 'dashboard-monitoring-rrv-tbbm-summary',
                            params: {
                              previous_link: {
                                name: 'dashboard-monitoring-sal-jetty'
                              },
                              id: item.detail_id,
                              api: 'form_chain_tbbm'
                            }
                          }"
                        >
                          <feather-icon
                            class="cursor-pointer"
                            :icon="
                              summaryPermission(item, 'TBBM')
                                ? 'KeyIcon'
                                : 'ActivityIcon'
                            "
                          />
                        </b-button>
                      </span>
                      <span v-if="item.detail_type === 'form_chain_hm'">
                        <b-button
                          :variant="
                            summaryPermission(item, 'HM')
                              ? 'outline-danger'
                              : 'outline-primary'
                          "
                          :disabled="summaryPermission(item, 'HM')"
                          block
                          size="sm"
                          :to="{
                            name: 'dashboard-monitoring-rrv-hulu-migas-summary',
                            params: {
                              previous_link: {
                                name: 'dashboard-monitoring-sal-jetty'
                              },
                              id: item.detail_id,
                              api: 'form_chain_hm'
                            }
                          }"
                        >
                          <feather-icon
                            class="cursor-pointer"
                            :icon="
                              summaryPermission(item, 'HM')
                                ? 'KeyIcon'
                                : 'ActivityIcon'
                            "
                          />
                        </b-button>
                      </span>
                      <span v-if="item.detail_type === 'form_tug_boat'">
                        <b-button
                          :variant="
                            summaryPermission(item, 'FTB')
                              ? 'outline-danger'
                              : 'outline-primary'
                          "
                          :disabled="summaryPermission(item, 'FTB')"
                          block
                          size="sm"
                          :to="{
                            name: 'dashboard-monitoring-rrv-tug-boat-summary',
                            params: {
                              previous_link: {
                                name: 'dashboard-monitoring-sal-jetty'
                              },
                              id: item.detail_id,
                              api: ''
                            }
                          }"
                        >
                          <feather-icon
                            class="cursor-pointer"
                            :icon="
                              summaryPermission(item, 'FTB')
                                ? 'KeyIcon'
                                : 'ActivityIcon'
                            "
                          />
                        </b-button>
                      </span>
                    </small>
                  </b-col>
                </b-row>
              </div>
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import { BCard, BImg, BAlert } from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import LottieAnimation from 'lottie-vuejs/src/LottieAnimation.vue' // import lottie-vuejs
import useAppConfig from '@core/app-config/useAppConfig'
import { $themeColors } from '@themeConfig'
import useJwt from '@/auth/jwt/useJwt'
const $trackBgColor = '#EBEBEB'
import { ModelObj } from 'vue-3d-model'
import { reactive } from '@vue/composition-api'
import { getUserData } from '@/auth/utils'
import moment from 'moment'

export default {
  directives: {
    Ripple
  },
  components: {
    BCard,
    LottieAnimation,
    BImg,
    VueApexCharts,
    ModelObj,
    BAlert,
  },
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },
  setup() {
    const { anIncomingNotificationRiver } = useAppConfig()
    return {
      anIncomingNotificationRiver /* FirebaseFCM */
    }
  },
  computed: {
    playLottie: {
      get() {
        return this.lottie.play
      },
      set(val) {
        this.lottie.play = !val
      }
    },
    sortedArray: function () {
      function compare(a, b) {
        if (a.name < b.name) return -1
        if (a.name > b.name) return 1
        return 0
      }

      return this.arrays.sort(compare)
    },

    jettyKegiatan() {
      return (item) => {
        if (item.detail_type === 'form_tug_boat') {
          return item.kegiatan
        } else if (item.detail_type === 'form_chain_tbbm') {
          if (item.show_in) {
            return item.kegiatan
          } else {
            return item.kegiatan
          }
        } else {
          return item.kegiatan
        }
      }
    },
    summaryPermission() {
      // TODO mapping role case
      return (row, flag) => {
        let pemilik = row.pemilik // group // like query
        let user_id = row.user_id

        let admin =
          this.$can('SAL', '') ||
          this.$can('manage', 'jetty') ||
          this.$can('manage', 'qrcode')

        if (flag === 'TBBM') {
          if (admin) {
            return false
          }

          if (this.$can('manage', 'special') || this.$can('approvement', '')) {
            if (pemilik.includes(this.my_group)) {
              return false
            } else {
              return true
            }
          }

          if (this.$can('TBBM', '')) {
            if (this.user_id === user_id) {
              return false
            } else {
              return true
            }
          } else {
            return true
          }
        }

        let hm = this.$can('Hulu Migas', '')
        if (flag === 'FTB') {
          if (admin) {
            return false
          }

          if (pemilik === 'Hulu Migas') {
            if (hm) {
              return false
            } else {
              return true
            }
          } else {
            if (this.user_id === user_id) {
              return false
            } else {
              return true
            }
          }
        }
        if (flag === 'HM') {
          if (admin) {
            return false
          }
          if (hm) {
            return false
          } else {
            return true
          }
        }
      }
    }
  },
  data() {
    return {
      mode: false,
      lottie: {
        play: true
      },
      rotation: reactive({
        x: -Math.PI / 2,
        y: 0,
        z: 475
      }),
      my_group: getUserData().group?.name ?? '',
      user_id: getUserData().id ?? '',
      yoTimeoutDebounce: null,
      yo_isLoading: false,
      yo_isLoadingValue: 25,
      yoTimer: null,
      // DATA
      jetty: {},
      // jetty: {
      //   'JETTY 3': {
      //     sandar: 0,
      //     rencana_sandar: 0,
      //     children: [],
      //     children_rencana: []
      //   },
      //   'JETTY 2B': {
      //     sandar: 0,
      //     rencana_sandar: 0,
      //     children: [],
      //     children_rencana: []
      //   },
      //   'JETTY 2A': {
      //     sandar: 0,
      //     rencana_sandar: 0,
      //     children: [],
      //     children_rencana: []
      //   },
      //   'JETTY 1': {
      //     sandar: 0,
      //     rencana_sandar: 0,
      //     children: [],
      //     children_rencana: []
      //   }
      // },
      empty_jetty: {
        'JETTY 3': {
          sandar: 0,
          rencana_sandar: 0,
          children: [],
          children_rencana: []
        },
        'JETTY 2B': {
          sandar: 0,
          rencana_sandar: 0,
          children: [],
          children_rencana: []
        },
        'JETTY 2A': {
          sandar: 0,
          rencana_sandar: 0,
          children: [],
          children_rencana: []
        },
        'JETTY 1': {
          sandar: 0,
          rencana_sandar: 0,
          children: [],
          children_rencana: []
        }
      }
    }
  },
  async created() {
    console.log('overview sal', this.data)
    await this.fetchDataPosition()
    await this.fetchData()
  },
  mounted() {
    document.body.style.zoom = '100%'
  },
  destroyed() {
    document.body.style.zoom = '100%'
  },
  watch: {
    anIncomingNotificationRiver() {
      console.log(
        'anIncomingNotificationRiver ::',
        this.anIncomingNotificationRiver
      )
      let high_light_data = this.anIncomingNotificationRiver.data[`action`]
      let will_be_refresh = [
        'Kapal Sandar',
        'Telah Sandar',
        'Telah Berangkat',
        'Membatalkan Kegiatan',
        'Berencana Sandar',
        'Pindah Jetty'
      ]
      let go_refresh = will_be_refresh.some((data) =>
        high_light_data.includes(data)
      )
      console.log('vgo_refresh::', go_refresh)
      if (go_refresh) {
        this.jetty = this.empty_jetty
        this.fetchData()
      }
    }
  },
  methods: {
    moment,
    formatDateTime(value, format) {
      const data = moment(value).format(format)
      return data
    },
    formatJettyChildren(children) {
      try {
        if (children && children.length > 0) {
          let formattedNames = children.map((item, index) => {
            let name = item.boat_name || item.name_tug_boat;
            if(item.show_in == null && item.name_tug_boat) name = item.name_tug_boat;
            if (index === 0) {
              return `⛴️ ${name}<br/>⏰ ${this.formatDateTime(item.rencana_sandar,'D MMMM YYYY - HH:mm')}`;
            } else {
              return `⛴️ ${name}<br/>⏰ ${this.formatDateTime(item.rencana_sandar,'D MMMM YYYY - HH:mm')}`;
            }
          });
          return formattedNames.join('<hr/>');
        }
      } catch (error) {
        return '';
      }
      return '';
    },
    onLoad() {
      this.rotate()
    },
    rotate() {
      requestAnimationFrame(this.rotation)
      this.rotation.z += 0.01
    },
    copyText(string) {
      this.$copyText(string).then(
        () => {
          this.$alert({
            title: `Text copied : ${string}`,
            variant: 'primary',
            icon: 'CheckCircleIcon'
          })
        },
        (e) => {
          this.$alert({
            title: `Can not copy!`,
            variant: 'danger',
            icon: 'XCircleIcon'
          })
        }
      )
    },
    setAnimController(event) {},
    changeMode() {
      this.mode = !this.mode
      console.log('clicked')
    },
    isTimerDone() {
      clearInterval(this.yoTimer)
      this.yoTimer = null
      this.yo_isLoading = false
    },
    isTimerStart() {
      this.yo_isLoading = true
      this.rows = null
      this.yoTimer = setInterval(() => {
        // eslint-disable-next-line
        if (this.yo_isLoadingValue <= 75 && this.yo_isLoading >= 0) {
          this.yo_isLoadingValue += 25
        } else {
          this.yo_isLoadingValue = 0
        }
      }, 1000)
    },
    fetchDataPosition(){
      useJwt.http
        .get(`jetty_position`, {
          params: {
            from_page: "/analytic/jalur-sungai/jetty-view"
          }
        })
        .then((res) => {
          const { jetty_positions } = res.data
          jetty_positions.forEach((element) => {
            const jettyKey = element.position;
            if (!this.jetty[jettyKey]) {
              this.$set(this.jetty, jettyKey, {
                sandar: 0,
                rencana_sandar: 0,
                children: [],
                children_rencana: []
              });
            }
          })
        })
    },
    fetchData() {
      this.isTimerStart()
      useJwt.http
        .get(`vw_monitor_jetty/jetty_view`, {
          params: {
            from_page: "/analytic/jalur-sungai/jetty-view"
          }
        })
        .then((res) => {
          this.isTimerDone()
          const { jetty_view } = res.data

          const rencana_sandar = jetty_view.filter(
            (data) => data.flag === 'kapal_rencana_sandar'
          )
          const sandar = jetty_view.filter(
            (data) => data.flag === 'kapal_sandar' && data.posisi != 'null'
          )

          rencana_sandar.forEach((element) => {
            const jettyKey = element.posisi;

            try {
              this.jetty[jettyKey].children_rencana = element.children;
            } catch (error) {
              console.log('error:', error);
            }
            this.jetty[jettyKey].rencana_sandar = element?.total_items;
          });

          sandar.forEach((element) => {
            const jettyKey = element.posisi;
            if(jettyKey === "JETTY 3A") return;

            try {
              this.jetty[jettyKey].children = element?.children;
            } catch (error) {
              console.log('error:', error);
            }
            this.jetty[jettyKey].sandar = element.total_items;
          });
          // console.log('this.jetty', this.jetty);

        })
    }
  }
}
</script>
<style lang="scss" scoped>
.sal-jetty-light {
  transition: all 0.5s;
  .column {
    .card-count {
      position: -webkit-sticky;
      position: sticky;
      top: 8em;
      z-index: 100 !important;
      &:hover {
        transition: all 0.3s;
        scale: 1.2;
      }
    }
    .card-list-item {
      hr {
        margin-top: 0.2rem;
        margin-bottom: 0.2rem;
      }
      &:hover {
        transition: all 0.8s;
        scale: 1.2;
        .card-item {
          z-index: 99 !important;
          .image {
            animation-name: boat;
            animation-duration: 1.5s;
            @keyframes boat {
              0% {
                transform: rotate(40deg);
              }
              25% {
                transform: rotate(95deg);
              }
              50% {
                transform: rotate(-40deg);
              }
            }
            /*scale: 0.2;*/
          }
        }
      }
      .row-item {
        .col-item {
          .card-item {
            .image {
              transition: all 0.5s;
            }
          }
        }
      }
    }
  }
}
.sal-jetty {
  transition: all 0.5s;
  .row-content {

  }
  .sal-title {
    /*transform: matrix(4, 1, -1, 4, 80, 80);
    scale: 0.11;
    position: absolute;
    right: 0;
    width: 100%;*/
  }
  .sal-content {
    margin-left: 18rem;
  }
  .sal-content-sandar {
    margin-top: -15rem;
  }

  .column {
    transform: matrix(3, 1, -2, 2, 80, 80);
    scale: 0.2;
    box-shadow: 10px 10px 5px lightblue;
    transition: all 0.5s;
    &:hover {
      transform: matrix(4, 0, 0, 3, 80, 80);
      scale: 0.25;
      z-index: 100;
      box-shadow: 0px 0px 0px lightblue;
      .card-list-item {
        .row-item {
          .col-item {
            .card-item {
              .image {
                animation-name: boat;
                animation-duration: 1.5s;
                @keyframes boat {
                  0% {
                    transform: rotate(40deg);
                  }
                  25% {
                    transform: rotate(95deg);
                  }
                  50% {
                    transform: rotate(-40deg);
                  }
                }
                /*scale: 0.2;*/
              }
              .divider {
                animation-name: divider;
                animation-duration: 1s;
              }
              @keyframes divider {
                0% {
                  margin-bottom: 0em;
                }
                25% {
                  margin-bottom: -20em;
                }
                50% {
                  margin-bottom: 0em;
                }
              }
            }
          }
        }
      }
    }
    .card-count {
      position: -webkit-sticky;
      position: sticky;
      top: 2em;
      z-index: 100 !important;
      animation-name: count;
      animation-duration: 1s;
      @keyframes count {
        0% {
          scale: 0;
        }
        25% {
          scale: 1;
        }
        50% {
          scale: 0;
        }
      }
    }
    .card-list-item {
      .row-item {
        .col-item {
          .card-item {
            .divider {
              /*
              transform: matrix(4, -2, 6, 8, 80, 80);
              scale: 0.2;
              */
              transition: all 0.5s;
            }
          }
        }
      }
    }
  }
}
</style>
