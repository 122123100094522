var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: _vm.mode ? 'sal-jetty' : 'sal-jetty-light'
  }, [_c('b-card', {
    staticClass: "sal-header sal-title"
  }, [_c('div', {
    staticClass: "divider"
  }, [_c('div', {
    staticClass: "divider-text"
  }, [_c('h3', [_vm._v("JETTY VIEW TODAY")]), _c('small', [_vm._v("Monitoring kondisi Jetty saat ini.")]), _c('br'), _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.changeMode();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.mode ? '3D View' : '2D View') + " ")]), _vm.yo_isLoading ? _c('div', {
    staticClass: "progress-wrapper"
  }, [_c('b-card-text', {
    staticClass: "mb-0"
  }, [_vm._v(" Memuat data… " + _vm._s(_vm.yo_isLoadingValue + '%') + " ")]), _c('b-progress', {
    key: _vm.yo_isLoadingValue,
    class: 'progress-bar-info',
    attrs: {
      "animated": "",
      "value": _vm.yo_isLoadingValue,
      "variant": "info",
      "max": "100"
    }
  })], 1) : _vm._e()], 1)])]), _c('b-row', {
    staticClass: "sal-content row-content justify-content-center match-height"
  }, _vm._l(_vm.jetty, function (jettyItem, jettyKey) {
    return _c('b-col', {
      key: jettyKey,
      staticClass: "column",
      attrs: {
        "md": "2"
      }
    }, [_c('b-card', {
      staticClass: "card-count justify-content-center"
    }, [_c('h5', {
      staticClass: "match-height"
    }, [_vm._v(_vm._s(jettyKey))]), _c('hr'), _c('small', {
      staticClass: "justify-content-center text-center",
      staticStyle: {
        "font-size": "14px"
      }
    }, [_vm._v(" Rencana Sandar: "), _c('b-badge', {
      directives: [{
        name: "b-popover",
        rawName: "v-b-popover.hover.top.html",
        value: _vm.formatJettyChildren(jettyItem === null || jettyItem === void 0 ? void 0 : jettyItem.children_rencana),
        expression: "formatJettyChildren(jettyItem?.children_rencana)",
        modifiers: {
          "hover": true,
          "top": true,
          "html": true
        }
      }, {
        name: "ripple",
        rawName: "v-ripple.400",
        value: 'rgba(113, 102, 240, 0.15)',
        expression: "'rgba(113, 102, 240, 0.15)'",
        modifiers: {
          "400": true
        }
      }],
      attrs: {
        "variant": "primary",
        "content": _vm.content,
        "title": "🔖 Kapal Rencana Sandar"
      }
    }, [_vm._v(" " + _vm._s(jettyItem.rencana_sandar) + " ")]), _c('hr'), _c('strong', [_vm._v("Sandar : ")]), _c('b-badge', {
      attrs: {
        "variant": "warning"
      }
    }, [_vm._v(" " + _vm._s(jettyItem.sandar) + " ")])], 1)])], 1);
  }), 1), _c('b-row', {
    staticClass: "sal-content-sandar row-content justify-content-center"
  }, _vm._l(_vm.jetty, function (jettyItem, jettyKey) {
    return _c('b-col', {
      key: jettyKey,
      staticClass: "column",
      attrs: {
        "md": "2"
      }
    }, _vm._l(jettyItem.children, function (item, index) {
      return _c('b-card', {
        key: index,
        staticClass: "card-list-item"
      }, [_c('div', {
        staticClass: "row-item"
      }, [_c('div', {
        staticClass: "col-item"
      }, [_c('div', {
        staticClass: "card-item mb-0"
      }, [_c('b-row', [_c('b-col', {
        attrs: {
          "cols": "12"
        }
      }, [_c('b-img', {
        staticClass: "congratulation-medal image",
        staticStyle: {
          "opacity": "0.1"
        },
        attrs: {
          "lazy": "",
          "src": "boat2.png",
          "height": "130"
        }
      })], 1), _c('b-col', [_c('small', [_c('b-badge', {
        attrs: {
          "variant": 'info'
        }
      }, [_c('feather-icon', {
        attrs: {
          "icon": "UsersIcon"
        }
      }), _c('span', {
        staticStyle: {
          "font-size": "9px"
        }
      }, [_vm._v(" " + _vm._s(item.pemilik) + " ")])], 1), _c('hr'), _c('small', {
        directives: [{
          name: "b-tooltip",
          rawName: "v-b-tooltip.hover",
          modifiers: {
            "hover": true
          }
        }],
        staticClass: "cursor-pointer",
        attrs: {
          "title": "Nomor PKK"
        }
      }, [!item.show_in ? _c('span', {
        staticStyle: {
          "font-size": "9px"
        },
        on: {
          "click": function click($event) {
            return _vm.copyText(item.no_pkk || item.no_pkk_tug_boat);
          }
        }
      }, [_c('strong', [_vm._v(_vm._s(item.no_pkk || item.no_pkk_tug_boat))])]) : _c('span', {
        staticStyle: {
          "font-size": "9px"
        },
        on: {
          "click": function click($event) {
            return _vm.copyText(item.no_pkk_tug_boat);
          }
        }
      }, [_c('strong', [_vm._v(_vm._s(item.no_pkk_tug_boat))])])]), _c('hr'), _c('strong', {
        directives: [{
          name: "b-tooltip",
          rawName: "v-b-tooltip.hover",
          modifiers: {
            "hover": true
          }
        }],
        attrs: {
          "title": "Nama Kapal"
        }
      }, [!item.show_in ? _c('span', {
        staticStyle: {
          "font-size": "14px"
        }
      }, [_c('strong', [_vm._v(_vm._s(item.boat_name || item.name_tug_boat))])]) : _c('span', {
        staticStyle: {
          "font-size": "14px"
        }
      }, [_c('strong', [_vm._v(_vm._s(item.name_tug_boat))])])]), _c('hr'), _c('span', {
        directives: [{
          name: "b-tooltip",
          rawName: "v-b-tooltip.hover",
          modifiers: {
            "hover": true
          }
        }],
        attrs: {
          "title": "Kegiatan"
        }
      }, [_c('strong', {
        staticStyle: {
          "font-size": "11px"
        }
      }, [_vm._v(" " + _vm._s(item.kegiatan) + " ")])]), _c('hr'), item.detail_type === 'form_chain_tbbm' ? _c('span', [_c('b-button', {
        attrs: {
          "variant": _vm.summaryPermission(item, 'TBBM') ? 'outline-danger' : 'outline-primary',
          "disabled": _vm.summaryPermission(item, 'TBBM'),
          "block": "",
          "size": "sm",
          "to": {
            name: 'dashboard-monitoring-rrv-tbbm-summary',
            params: {
              previous_link: {
                name: 'dashboard-monitoring-sal-jetty'
              },
              id: item.detail_id,
              api: 'form_chain_tbbm'
            }
          }
        }
      }, [_c('feather-icon', {
        staticClass: "cursor-pointer",
        attrs: {
          "icon": _vm.summaryPermission(item, 'TBBM') ? 'KeyIcon' : 'ActivityIcon'
        }
      })], 1)], 1) : _vm._e(), item.detail_type === 'form_chain_hm' ? _c('span', [_c('b-button', {
        attrs: {
          "variant": _vm.summaryPermission(item, 'HM') ? 'outline-danger' : 'outline-primary',
          "disabled": _vm.summaryPermission(item, 'HM'),
          "block": "",
          "size": "sm",
          "to": {
            name: 'dashboard-monitoring-rrv-hulu-migas-summary',
            params: {
              previous_link: {
                name: 'dashboard-monitoring-sal-jetty'
              },
              id: item.detail_id,
              api: 'form_chain_hm'
            }
          }
        }
      }, [_c('feather-icon', {
        staticClass: "cursor-pointer",
        attrs: {
          "icon": _vm.summaryPermission(item, 'HM') ? 'KeyIcon' : 'ActivityIcon'
        }
      })], 1)], 1) : _vm._e(), item.detail_type === 'form_tug_boat' ? _c('span', [_c('b-button', {
        attrs: {
          "variant": _vm.summaryPermission(item, 'FTB') ? 'outline-danger' : 'outline-primary',
          "disabled": _vm.summaryPermission(item, 'FTB'),
          "block": "",
          "size": "sm",
          "to": {
            name: 'dashboard-monitoring-rrv-tug-boat-summary',
            params: {
              previous_link: {
                name: 'dashboard-monitoring-sal-jetty'
              },
              id: item.detail_id,
              api: ''
            }
          }
        }
      }, [_c('feather-icon', {
        staticClass: "cursor-pointer",
        attrs: {
          "icon": _vm.summaryPermission(item, 'FTB') ? 'KeyIcon' : 'ActivityIcon'
        }
      })], 1)], 1) : _vm._e()], 1)])], 1)], 1)])])]);
    }), 1);
  }), 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }